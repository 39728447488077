import React, { useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"
import whiteLogo from "./../../images/whiteLogo.png"
import "./footer.scss"
import SocialIcons from "../shared/SocialIcons"
import FooterLists from "./FooterLists"
const Footer = props => {
  return (
    <footer>
      <div className="upper-footer">
        <div className="footer-links">
          {FooterLists.map(list => (
            <div className="links-list">
              <div
                className="footer-link"
                style={{
                  marginBottom: "10px",
                  fontWeight: 600,
                }}
              >
                {list.title}
              </div>
              {list.items.map(listItem => (
                <Link className="footer-link" to={listItem.link}>
                  {listItem.title}
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div className="contact-us-widget">
          <div>
            <div
              style={{
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              Connect With Us
            </div>
            <SocialIcons />
          </div>
          <div className="Address">
            <div className="title">US Office Address</div>
            <div className="address HeadquarterSection">
              <div>138 Working Ranch. Irvine, CA 92602</div>
            </div>          
            <div className="email HeadquarterSection">
              <div>info@foothillsolutions.com</div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <img className="footer-logo" src={whiteLogo} height={25} />
        <div>{`© ${new Date().getFullYear()} Foothill Technology Solutions.`}</div>
      </div>
    </footer>
  )
}

export default Footer
