import React from 'react';
import Charles from './images/testimonials/charles.jpg';
import tony from './images/testimonials/tony.jpg';
import paul from './images/testimonials/paul.png';

export const TESTIMONIALS_PEOPLE = [
  {
    name: "Charles Fink",
    position: "CRM Practice Manager",
    avatar: () => <img src={Charles} />
  },
  {
    name: "Tony Smith",
    position: "Co-Founder & CEO Restaurant365",
    avatar: () => <img src={tony} />,
  },
  {
    name: "Paul Spruiell ",
    position: "VP Group Operations",
    avatar: () => <img src={paul} />,
  },
]

export const PAGES = {
  HOME: "home",
  ABOUT_US: "about",
  CAREERS: "careers",
  BLOGS: "blogs",
  CONTACT_US: "contactus",
}

export const HR_EMAIL = "info@FTS.com";
export const CAREERS_EMAIL = "careers@foothillsolutions.com";
