import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header"
import Footer from "../Footer"
import "./layout.css"
import { PAGES } from "../../constants"

const Layout = ({ currentPage, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {currentPage === PAGES.HOME ? (
        <>
          <main>{children}</main>
        </>
      ) : (
        <>
          <Header
            currentPage={currentPage}
            siteTitle={data.site.siteMetadata?.title || `Title`}
          />
          <div>
            <main>{children}</main>
          </div>
        </>
      )}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
