import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap"
import "./style.scss"
import logo from "./../../images/logo.png"
import whiteLogo from "./../../images/whiteLogo.png"
import { NavItems } from "./constants"
import { PAGES } from "../../constants"
import { Container } from "@material-ui/core"

const Header = ({ currentPage }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className={`${currentPage}-header header`}>
      <Container>
        <Navbar className="navbar" light expand="md">
          <Link href="/">
            <img
              style={{ margin: 0 }}
              className="logo"
              alt="logo"
              src={currentPage === PAGES.HOME ? whiteLogo : logo}
            />
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto nav-items " navbar>
              {NavItems.map(navItem => (
                <NavItem
                  className={`nav-item ${
                    currentPage === navItem.pageId ? "active-nav-item" : ""
                  }`}
                >
                  <Link className="nav-link" to={navItem.to}>
                    {navItem.label}
                  </Link>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  )
}

export default Header
