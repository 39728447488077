import React from "react"
import { Link } from "gatsby"
import fbicon from "./../../../images/Social_icons/facebook.svg"
import Linkedinicon from "./../../../images/Social_icons/linkedin.svg"
import Youtubeicon from "./../../../images/Social_icons/Youtube.svg"
import Twittericon from "./../../../images/Social_icons/twitter.svg"
import "./Socialicons.scss"
import { SOCIAL_LINKS } from "../../../../static/content/links"

const SocialIcons = props => (
  <div className="foothill-social-icons">
    <a className="footer-link" href={SOCIAL_LINKS.Facebook} target="_blank">
      <img className="foothill-social-icon" alt="" src={fbicon} />
    </a>
    <a className="footer-link" href={SOCIAL_LINKS.LinkedIn} target="_blank">
      <img className="foothill-social-icon" alt="" src={Linkedinicon} />
    </a>
    <a className="footer-link" href={SOCIAL_LINKS.Youtube} target="_blank">
      <img className="foothill-social-icon" alt="" src={Youtubeicon} />
    </a>
  </div>
)

export default SocialIcons
