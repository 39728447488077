const NavigateList = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Careers",
    link: "/careers",
  },

  {
    title: "Contact Us",
    link: "/contactus",
  },
]

const Home = [
  {
    title: "Our Services",
    link: "/#our-services",
  },
  {
    title: "Our Clients",
    link: "/#our-clients",
  },
  {
    title: "Our Testimonials",
    link: "/#our-testimonials",
  },
]

const About = [
  {
    title: "Who we are",
    link: "/about/#WhoWeAre",
  },
  {
    title: "Meet the Team",
    link: "/about/#MeettheTeam",
  },
  {
    title: "Our Advantages",
    link: "/about/#OurAdvantages",
  },
]
const FooterLists = [
  { title: "Navigate", items: NavigateList },
  { title: "Home", items: Home },
  { title: "About Us", items: About },
]

export default FooterLists
